import React from "react";
import CloseSquare from "../images/close-square.svg";
import IconButton from "@mui/material/IconButton";

//TODO replace everywhere in code to use this component
export const CloseImageIconButton = ({ handleClose, size = 24 }) => {
  return (
    <IconButton
      color="primary"
      aria-label="add to shopping cart"
      className="pt-0 pb-0"
      disableRipple
      onClick={handleClose}
    >
      <img
        src={CloseSquare}
        alt="CloseSquare"
        className="img-dark-filter"
        width={size}
        height={size}
      />
    </IconButton>
  );
};
