import { createSlice } from "@reduxjs/toolkit";
import {
  changePasswordAction,
  connectAccountAction,
  deleteAccountAction,
  deleteSessionsAction,
  getMeAction,
  getSessions,
  updateMeAction,
  updateMeCreatorAction,
} from "../../../../middlewares/web/profile/myProfile/me";
import {
  toggleDarkModeAction,
  updateLanguageForUser,
} from "../../../../middlewares/web/profile/myProfile/profiledisplay";
import { UpdateAccountAction } from "../../../../middlewares/web/profile/myProfile/account";
import { GetSpotify } from "../../../../middlewares/web/profile/myProfile/spotify";
import {
  getSocialLinksAction,
  setSocialLinksAction,
} from "../../../../middlewares/web/profile/myProfile/sociallinks";

// isDeleteMeFulFilled
export const meSlice = createSlice({
  name: "me",
  initialState: {
    meLoading: false,
    meErrorMessage: "",
    meError: false,
    me: null,
    sessions: null,
    sessionsLoading: false,
    sessionsError: false,
    sessionsErrorMessage: "",
    deleteSessionsSuccess: false,
    deleteSessionsError: false,
    deleteSessionsMessages: "",
    changePasswordError: false,
    changePasswordSuccess: false,
    changePasswordMessage: "",
    connectAccountError: false,
    connectAccountSuccess: false,
    connectAccountMessage: "",
    socialLinks: null,
    socialLinksLoading: false,
    socialLinksError: false,
    socialLinksErrorMessage: "",
  },
  reducers: {
    resetConnectAccount: (state) => {
      let { me } = state;
      state.connectAccountError = false;
      state.connectAccountSuccess = false;
      state.connectAccountMessage = "";
      state.me = me;
    },
    updateCreatorRequest: (state) => {
      let { me } = state;
      state.me = { ...me, creatorRequestPending: true };
    },
    resetChangePasswordError: (state) => {
      state.changePasswordError = false;
      state.changePasswordMessage = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMeAction.pending, (state) => {
        state.meLoading = true;
        state.meErrorMessage = "";
        state.meError = false;
        state.sessions = null;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
        state.connectAccountError = false;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "";
      })
      .addCase(getMeAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = action.payload ? action.payload.response : null;
        state.meError = false;
        state.meErrorMessage = "";
      })
      .addCase(getMeAction.rejected, (state, action) => {
        state.meLoading = false;
        state.meError = true;
        state.meErrorMessage = action.payload.message;
        state.me = null;
      })
      .addCase(updateMeAction.pending, (state) => {
        state.meLoading = true;
        state.meErrorMessage = "";
        state.meError = false;
        state.sessions = null;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
        state.connectAccountError = false;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "";
      })
      .addCase(updateMeAction.fulfilled, (state, action) => {
        const {
          payload: { response, message },
        } = action;
        state.meLoading = false;
        state.meErrorMessage = message;
        let data = {};
        if (response) {
          data = response;
        }
        let me = state.me;
        let tempObj = { ...me, ...data };
        state.me = {
          ...tempObj,
          isIdentityVerified:
            tempObj.bio && tempObj.profileImg && tempObj.coverImg,
        };
        state.meError = false;
      })
      .addCase(updateMeAction.rejected, (state, action) => {
        state.meLoading = false;
        state.meErrorMessage = action.payload.message;
        state.meError = true;
      })
      .addCase(updateMeCreatorAction.pending, (state) => {
        state.meLoading = true;
        state.meErrorMessage = "";
        state.meError = false;
        state.sessions = null;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
        state.connectAccountError = false;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "";
      })
      .addCase(updateMeCreatorAction.fulfilled, (state, action) => {
        const {
          payload: { response, message },
        } = action;
        state.meLoading = false;
        state.meErrorMessage = message;
        let data = {};
        if (response) {
          data = response;
        }
        let me = state.me;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
        state.meError = false;
      })
      .addCase(updateMeCreatorAction.rejected, (state, action) => {
        state.meLoading = false;
        state.meErrorMessage = action.payload.message;
        state.meError = true;
      })
      .addCase(deleteAccountAction.pending, (state) => {
        state.meLoading = true;
        state.meErrorMessage = "";
        state.meError = false;
        state.sessions = null;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
        state.connectAccountError = false;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "";
      })
      .addCase(deleteAccountAction.fulfilled, (state, action) => {
        const {
          payload: { message },
        } = action;
        state.meLoading = false;
        state.meErrorMessage = message;
        state.me = null;
        state.meError = false;
        localStorage.removeItem("user-details");
      })
      .addCase(deleteAccountAction.rejected, (state, action) => {
        state.meLoading = false;
        state.meErrorMessage = action.payload.message;
        state.meError = true;
      })
      .addCase(changePasswordAction.pending, (state) => {
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
      })
      .addCase(changePasswordAction.fulfilled, (state, action) => {
        state.changePasswordMessage = action.payload.response.message;
        state.changePasswordError = false;
        state.changePasswordSuccess = true;
      })
      .addCase(changePasswordAction.rejected, (state, action) => {
        const {
          payload: { message },
        } = action;
        state.changePasswordError = true;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = message;
      })
      .addCase(getSessions.pending, (state) => {
        state.sessions = null;
        state.sessionsLoading = true;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
      })
      .addCase(getSessions.fulfilled, (state, action) => {
        state.sessions = action.payload.response;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = action.payload.message;
      })
      .addCase(getSessions.rejected, (state, action) => {
        state.sessionsLoading = false;
        state.sessionsError = true;
        state.sessionsErrorMessage = action.payload.message;
        state.sessions = null;
      })
      .addCase(deleteSessionsAction.pending, (state) => {
        state.deleteSessionsSuccess = false;
        state.deleteSessionsError = false;
        state.deleteSessionsMessages = "";
      })
      .addCase(deleteSessionsAction.fulfilled, (state, action) => {
        let tempSessions = JSON.parse(JSON.stringify(state.sessions));
        state.sessions = tempSessions?.filter(
          (session) => !action.payload.ids.includes(session.id)
        );
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = false;
        state.deleteSessionsMessages = action.payload.message;
      })
      .addCase(deleteSessionsAction.rejected, (state, action) => {
        state.deleteSessionsSuccess = false;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = action.payload.message;
      })
      .addCase(connectAccountAction.fulfilled, (state, action) => {
        let { me } = state;
        let data = action.payload.data;
        let tempObj = { ...me, ...data };
        state.connectAccountError = false;
        state.connectAccountSuccess = true;
        state.me = tempObj;
        state.connectAccountMessage = "Account Linked Successfully!";
      })
      .addCase(connectAccountAction.rejected, (state) => {
        let { me } = state;
        state.connectAccountError = true;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "Account could not be linked!";
        state.me = me;
      })
      .addCase(updateLanguageForUser.pending, (state) => {
        state.meLoading = true;
        state.meErrorMessage = "";
        state.meError = false;
        state.sessions = null;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
        state.connectAccountError = false;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "";
      })
      .addCase(updateLanguageForUser.fulfilled, (state, action) => {
        const {
          payload: { response, message },
        } = action;
        state.meLoading = false;
        state.meErrorMessage = message;
        let data = {};
        if (response) {
          data = response;
        }
        let me = state.me;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
        state.meError = false;
      })
      .addCase(updateLanguageForUser.rejected, (state, action) => {
        state.meLoading = false;
        state.meErrorMessage = action.payload.message;
        state.meError = true;
      })
      .addCase(toggleDarkModeAction.pending, (state) => {
        state.meLoading = true;
        state.meErrorMessage = "";
        state.meError = false;
        state.sessions = null;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
        state.connectAccountError = false;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "";
      })
      .addCase(toggleDarkModeAction.fulfilled, (state, action) => {
        const {
          payload: { response, message },
        } = action;
        state.meLoading = false;
        state.meErrorMessage = message;
        let data = {};
        if (response) {
          data = response;
        }
        let me = state.me;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
        state.meError = false;
      })
      .addCase(toggleDarkModeAction.rejected, (state, action) => {
        state.meLoading = false;
        state.meErrorMessage = action.payload.message;
        state.meError = true;
      })
      .addCase(UpdateAccountAction.pending, (state) => {
        state.meLoading = true;
        state.meErrorMessage = "";
        state.meError = false;
        state.sessions = null;
        state.sessionsLoading = false;
        state.sessionsError = false;
        state.sessionsErrorMessage = "";
        state.deleteSessionsSuccess = true;
        state.deleteSessionsError = true;
        state.deleteSessionsMessages = "";
        state.changePasswordError = false;
        state.changePasswordSuccess = false;
        state.changePasswordMessage = "";
        state.connectAccountError = false;
        state.connectAccountSuccess = false;
        state.connectAccountMessage = "";
      })
      .addCase(UpdateAccountAction.fulfilled, (state, action) => {
        const {
          payload: { response, message },
        } = action;
        state.meLoading = false;
        state.meErrorMessage = message;
        let data = {};
        if (response) {
          data = response;
        }
        let me = state.me;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
        state.meError = false;
      })
      .addCase(GetSpotify.fulfilled, (state, action) => {
        const {
          payload: { response, message },
        } = action;
        state.meLoading = false;
        state.meErrorMessage = message;
        let data = {};
        if (response) {
          data = response;
        }
        let me = state.me;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
        state.meError = false;
        window.location = "/user/my-profile";
      })
      .addCase(UpdateAccountAction.rejected, (state, action) => {
        state.meLoading = false;
        state.meErrorMessage = action.payload.message;
        state.meError = true;
      })
      .addCase(getSocialLinksAction.pending, (state) => {
        state.socialLinksLoading = true;
        state.socialLinksError = false;
        state.socialLinksErrorMessage = "";
      })
      .addCase(getSocialLinksAction.fulfilled, (state, action) => {
        state.socialLinksLoading = false;
        state.socialLinks = action.payload.response;
        state.socialLinksError = false;
        state.socialLinksErrorMessage = action.payload.message;
      })
      .addCase(getSocialLinksAction.rejected, (state, action) => {
        state.socialLinksLoading = false;
        state.socialLinksError = true;
        state.socialLinksErrorMessage = action.payload.message;
        state.socialLinks = null;
      })
      // Set Social Links Action
      .addCase(setSocialLinksAction.pending, (state) => {
        state.socialLinksLoading = true;
        state.socialLinksError = false;
        state.socialLinksErrorMessage = "";
      })
      .addCase(setSocialLinksAction.fulfilled, (state, action) => {
        let { me } = state;
        let { type, url } = action.payload.response;
        let socialLinks = me?.socialLinks;
        console.log(socialLinks, "socialLinks");
        let tempArray = socialLinks?.map((link) => {
          if (link.type === type) {
            return { ...link, url: url };
          }
          return link;
        });
        state.socialLinksLoading = false;
        state.me = { ...me, socialLinks: tempArray };
        state.socialLinksError = false;
        state.socialLinksErrorMessage = action.payload.message;
      })
      .addCase(setSocialLinksAction.rejected, (state, action) => {
        state.socialLinksLoading = false;
        state.socialLinksError = true;
        state.socialLinksErrorMessage = action.payload.message;
      });
  },
});

export const { resetConnectAccount, resetChangePasswordError } =
  meSlice.actions;

export default meSlice.reducer;
