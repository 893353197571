import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { MainLayout } from "../newComponents/organisms";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { useTheme } from "../newComponents/atoms";

const NotFound = lazy(() => import("../pages/web/common/NotFound"));
const Login = lazy(() => import("../pages/auth/session/Login"));
const CreatorHomeScreen = lazy(() => import("../pages/web/creatorSide/Home"));
const CreatorProfileScreen = lazy(() =>
  import("../pages/web/creatorSide/Profile")
);
const CreatorVaultScreen = lazy(() =>
  import("../pages/web/creatorSide/Vault")
);
const NewPost = lazy(() => import("../pages/web/fanSide/NewPost"));
const EditPost = lazy(() => import("../pages/web/fanSide/NewPost"));
const Queue = lazy(() => import("../pages/web/creatorSide/Queue"));
const AllSuggestions = lazy(() => import("../pages/web/common/AllSuggestions"));
const ArchivePage = lazy(() => import("../pages/web/creatorSide/ArchivePage"));

const generatePath = (slug) => {
  return `/${slug}`;
};

const creatorRoutes = [
  {
    path: generatePath("home"),
    element: <CreatorHomeScreen />,
  },
  {
    path: generatePath("my-profile"),
    element: <CreatorProfileScreen />,
  },
  {
    path: generatePath("vault"),
    element: <CreatorVaultScreen />,
  },
  {
    path: generatePath("new-post"),
    element: <NewPost />,
  },
  {
    path: generatePath("edit-post/:id"),
    element: <EditPost />,
  },
  {
    path: generatePath("queue"),
    element: <Queue />,
  },
  {
    path: generatePath("all-suggestions"),
    element: <AllSuggestions />,
  },
  {
    path: generatePath("archive"),
    element: <ArchivePage />,
  }
];

const CreatorNavigation = () => {
  useDocumentTitle("YouOnly");
  const { darkMode } = useTheme();
  const user = JSON.parse(localStorage.getItem("user-details"));
  // const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });
  const isTokenValid = true;
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={!user || !isTokenValid ? <Login /> : <Navigate to="home" />}
        />
        {/* if user is not present show unauthorized ui */}
        <Route
          element={
            !user || !isTokenValid ? (
              <Navigate to="/" />
            ) : (
              <>
                {/* //Protection for when a User tries to access a Creator Page  */}
                {user?.userType === "user" ? (
                  <Navigate to="/user/home" />
                ) : (
                  <MainLayout type="creator" />
                )}
              </>
            )
          }
        >
          {creatorRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
          {/* all undefined routes are handled here for admin */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </MuiThemeProvider>
  );
};

export default CreatorNavigation;
