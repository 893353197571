import React, { lazy, useCallback, useEffect } from "react";
import { MainLayout } from "../newComponents/organisms";
import Login from "../pages/auth/session/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { useTheme } from "../newComponents/atoms";
import { useDispatch, useSelector } from "react-redux";
import { validateTokenWeb } from "../store/middlewares/auth/session/auth";
import { getDecryptedToken } from "../utils/CryptoJS";

const EditPost = lazy(() => import("../pages/web/fanSide/EditPost"));
const UserHomeScreen = lazy(() => import("../pages/web/fanSide/Home"));
const UserHomeProfilePost = lazy(() =>
  import("../pages/web/fanSide/UserHomeProfilePost")
);
const UserProfileScreen = lazy(() => import("../pages/web/fanSide/Profile"));
const UserHomePost = lazy(() => import("../pages/web/fanSide/UserHomePost"));
const UserBookmarks = lazy(() => import("../pages/web/fanSide/Bookmarks"));
const UserCollection = lazy(() => import("../pages/web/fanSide/Collection"));
const NotFound = lazy(() => import("../pages/web/common/NotFound"));
const BecomeACreator = lazy(() =>
  import("../pages/web/fanSide/BecomeACreator")
);
const NewPost = lazy(() => import("../pages/web/fanSide/NewPost"));
const AllSuggestions = lazy(() => import("../pages/web/common/AllSuggestions"));
const CompleteProfile = lazy(() =>
  import("../pages/web/fanSide/CompleteProfile")
);
const Explore = lazy(() => import("../pages/web/fanSide/Explore"));

const generatePath = (slug) => {
  return `/${slug}`;
};

const userRoutes = [
  {
    path: generatePath("home"),
    element: <UserHomeScreen />,
  },
  {
    path: generatePath("profile/:username"),
    element: <UserHomeProfilePost />,
  },
  {
    path: generatePath("my-profile"),
    element: <UserProfileScreen />,
  },
  {
    path: generatePath("/post/:id"),
    element: <UserHomePost />,
  },
  {
    path: generatePath("bookmarks"),
    element: <UserBookmarks />,
  },
  {
    path: generatePath("user-lists"),
    element: <UserCollection />,
  },
  {
    path: generatePath("become-a-creator"),
    element: <BecomeACreator />,
  },
  {
    path: generatePath("post"),
    element: <NewPost />,
  },
  {
    path: generatePath("all-suggestions"),
    element: <AllSuggestions />,
  },
  {
    path: generatePath("complete-profile"),
    element: <CompleteProfile />,
  },
  {
    path: generatePath("explore"),
    element: <Explore />,
  },
];

const UserNavigation = () => {
  useDocumentTitle("YouOnly");
  const { darkMode } = useTheme();

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  const user = JSON.parse(localStorage.getItem("user-details"));

  const dispatch = useDispatch();

  const { isTokenValid } = useSelector((state) => state.webauth);
  const checkToken = useCallback(() => {
    if (user) {
      dispatch(validateTokenWeb({ token: getDecryptedToken(user?.token) }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={
            !localStorage.getItem("user-details") || !isTokenValid ? (
              <Login />
            ) : (
              <Navigate to="home" />
            )
          }
        />
        {/* if user is not present show unauthorized ui */}
        <Route
          element={
            !localStorage.getItem("user-details") || !isTokenValid ? (
              <Navigate to="/" />
            ) : (
              <>
                {/* //Protection for when a Creator tries to access a User Page  */}
                {user?.userType === "creator" ? (
                  <Navigate to="/creator/home" />
                ) : (
                  <MainLayout />
                )}
              </>
            )
          }
        >
          {userRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
          {/* all undefined routes are handled here for admin */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </MuiThemeProvider>
  );
};

export default UserNavigation;
