export const getFirstAnsweredId = (options) => {
  const answeredOption = options.find((option) => option.hasAnswered);
  return answeredOption ? answeredOption.id : null;
};

export const getFirstVotedId = (options) => {
  const votedOption = options.find((option) => option.hasVoted);
  return votedOption ? votedOption.id : null;
};

export const getFileSrc = (file) => {
  if (file instanceof Blob || file instanceof File) {
    return URL.createObjectURL(file);
  }
  return file.fileURL;
};
