import * as Yup from "yup";
import {
  emailRegex,
  spaceRegExp,
  specialCharactersRegExp,
  uppercaseRegExp,
} from "../regex";

export const email = Yup.string()
  .email("Email is Invalid.")
  .required("Email is Required.")
  .matches(emailRegex, "Email is Invalid");

export const Password = Yup.string()
  .min(8, "Invalid Credentials")
  .matches(uppercaseRegExp, "Invalid Credentials")
  .matches(specialCharactersRegExp, "Invalid Credentials")
  .matches(spaceRegExp, "Invalid Credentials")
  .max(50, "Invalid Credentials")
  .required("Password is Required");
