import React, { useState, useEffect } from "react";
import UserBlank from "./../../../../../../assets/images/blank.png";
import { timeAgo } from "../../../../../../utils/timeAgo";
import StoryModal from "../index";
import LikePost from "../../../../../atoms/LikePost";
import SendTipButton from "../../SendTipButton";
import ChatIconButton from "./ChatIconButton";
import { Box } from "@mui/material";
import { STORY_DURATION } from "../../../../../../constants";

const ProgressBars = ({ totalImages, currentIndex, progress }) => (
  <div className="flex w-full mb-2">
    {[...Array(totalImages)].map((_, index) => (
      <div key={index} className="h-1 bg-gray-300 flex-grow mx-0.5">
        {index === currentIndex && (
          <div
            className="h-full bg-blue-500 transition-all duration-100"
            style={{ width: `${progress}%` }}
          />
        )}
        {index < currentIndex && (
          <div
            className="h-full bg-blue-500 transition-all duration-100"
            style={{ width: `100%` }}
          />
        )}
      </div>
    ))}
  </div>
);

const StoryContent = ({ imageUrl, onNext }) => (
  <div className="relative w-full h-full aspect-[4/5]">
    <img
      src={imageUrl}
      alt="Story content"
      className="absolute inset-0 w-full h-full object-cover rounded-2xl"
      onClick={onNext}
    />
  </div>
);

const StoryViewer = ({
  images,
  onClose,
  user,
  createdAt,
  hasLiked,
  likesCount,
  postId,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const handleOnNext = () => {
    setProgress(0);
    if (currentIndex < images.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (images.length === 0) {
      onClose();
      return;
    }

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          if (currentIndex < images.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
            return 0;
          } else {
            clearInterval(timer);
            onClose();
            return 100;
          }
        }
        return prevProgress + 100 / (STORY_DURATION / 100);
      });
    }, 100);

    return () => clearInterval(timer);
  }, [currentIndex, images.length, onClose]);

  if (images.length === 0) {
    return null;
  }

  return (
    <StoryModal
      username={user.username}
      date={timeAgo(createdAt)}
      profileImage={user.profileImage || UserBlank}
      ProgressBarComponent={
        <ProgressBars
          totalImages={images.length}
          currentIndex={currentIndex}
          progress={progress}
        />
      }
      handleCancel={onClose}
    >
      <>
        <div className="flex-grow overflow-hidden relative">
          <StoryContent
            imageUrl={images[currentIndex].fileURL}
            onNext={handleOnNext}
          />
        </div>
      </>
      <div className="flex justify-end my-2 mr-2 mt-4">
        <Box className="mr-2">
          <LikePost
            likesCount={likesCount}
            hasLiked={hasLiked}
            isDisabled={false}
            postId={postId}
            showCounter={false}
          />
        </Box>

        <Box className="mr-2">
          <ChatIconButton />
        </Box>

        <Box>
          <SendTipButton />
        </Box>
      </div>
    </StoryModal>
  );
};

export default StoryViewer;
