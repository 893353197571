import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Box } from "@mui/material";
import { getPostCommentsAction } from "../../../../../../store/middlewares/web/post/posts";
import { SingleComment } from "../../../../../atoms";
import LoadMoreComments from "../LoadMoreComments";
import { AddCommentTextArea } from "../../../../../atoms";

const PostCommentsList = ({
  comments,
  postId,
  postCreatorUserId,
  allowCommentsPagination,
}) => {
  const { commentsLoading, nextCommentsCursor, commentsHasNextPage } =
    useSelector((state) => state.posts);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!allowCommentsPagination) {
      return;
    }
    dispatch(
      getPostCommentsAction({
        postId,
        cursor:
          comments?.length > 0 ? comments[comments.length - 1].id : undefined,
      })
    );
  }, [dispatch, postId]);
  const handleLoadMore = () => {
    if (!commentsHasNextPage) return;
    if (commentsLoading) return;
    dispatch(
      getPostCommentsAction({
        postId,
        cursor: nextCommentsCursor,
      })
    );
  };
  if (commentsLoading && comments.length === 0) {
    return (
      <Box className="bg-white rounded-2xl py-4 flex justify-center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      {allowCommentsPagination && comments.length === 0 && (
        <h3 className="font-18 comment-title bg-white text-darkblue">
          No comments yet
        </h3>
      )}
      {comments.length > 0 && (
        <h3 className="font-18 comment-title bg-white text-darkblue">
          Comments
        </h3>
      )}
      <AddCommentTextArea postId={postId} />
      <div className="ml-4 mr-4">
      {comments.map((comment) => (
        <SingleComment
          comment={comment}
          handleEdit={() => {}}
          postId={postId}
          postCreatorUserId={postCreatorUserId}
          key={comment?.id}
        />
      ))}
      {allowCommentsPagination && (
        <LoadMoreComments
          onLoadMore={handleLoadMore}
          loading={commentsLoading}
          comments={comments}
        />
      )}
      </div>
    </>
  );
};
export default PostCommentsList;
