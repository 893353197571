import React from "react";
import Verified from "./../../../../../assets/images/verified.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

function Postheader({ img, name, uname, isVerified = true, isLoggedInUser, id }) {
  const navigate = useNavigate();
  const [triggerNav, setTriggerNav] = useState(false);
  useEffect(() => {
    if (triggerNav) {
      if (isLoggedInUser) {
        navigate("/user/my-profile");
      } else {
        navigate(`/user/profile/${uname}`);
      }
    }
  }, [triggerNav]);

  const handlePostClick = () => {
    //trigger on Click to visit the user profile
    setTriggerNav(true);
  };

  return (
    <div className="flex gap-5 varified">
      <div
        className="relative cursor-pointer max-h-[60px] max-w-[60px] h-[60px] w-[60px] rounded-[50%] border-2 border-[#2799F6]"
        onClick={handlePostClick}
      >
        <img
          src={img}
          alt={name}
          className="w-full h-full object-cover rounded-[50%] pointer-events-none"
        />
        {isVerified && (
          <div className="verified-bg">
            <img src={Verified} alt="Verified" />
          </div>
        )}
      </div>
      <div
        className="flex flex-col justify-center cursor-pointer"
        onClick={handlePostClick}
      >
        <h3>{name}</h3>
        {uname && <p>@{uname}</p>}
      </div>
    </div>
  );
}

export default Postheader;
