import React from "react";
import { Box } from "@mui/material";
import Chart from "react-apexcharts";

const StatisticsChart = ({ checkedStats, colors, data }) => {
  const processData = () => {
    if (!data?.timeSeries) return { dates: [], series: [] };

    const allDates = new Set();
    const timeSeriesData = data.timeSeries;

    Object.keys(timeSeriesData).forEach((key) => {
      if (Array.isArray(timeSeriesData[key])) {
        timeSeriesData[key].forEach((item) => allDates.add(item.date));
      }
    });

    const sortedDates = [...allDates].sort((a, b) => new Date(a) - new Date(b));

    const series = Object.keys(checkedStats)
      .filter((key) => checkedStats[key] && timeSeriesData[key])
      .map((key) => {
        const dateToCount = new Map(
          timeSeriesData[key].map((item) => [item.date, item.count])
        );

        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          data: sortedDates.map((date) => dateToCount.get(date) || 0),
          color: colors[key].main,
        };
      });

    return { dates: sortedDates, series };
  };

  const { dates, series: activeSeries } = processData();

  const hasNoData = activeSeries.length === 0 || dates.length === 0;

  const chartOptions = {
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: activeSeries.map((series) => series.color),
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.1,
      },
    },
    grid: {
      show: true,
      borderColor: "#f1f1f1",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
      categories: dates,
      tickAmount: 6,
      labels: {
        formatter: function (value) {
          if (!value || hasNoData) return "";
          const date = new Date(value);
          return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          });
        },
        style: {
          fontSize: "12px",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      style: {
        fontSize: "14px",
        color: "#999",
      },
    },
  };

  return (
    <Box className="mb-6 bg-white rounded-lg p-4">
      <Chart
        options={chartOptions}
        series={hasNoData ? [] : activeSeries}
        type="area"
        height={200}
      />
    </Box>
  );
};

export default StatisticsChart;
