import React from "react";

const MessagesIcon = ({ color = "#292D32", size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 9.5C17 13.37 13.64 16.5 9.5 16.5L8.57001 17.62L8.02 18.28C7.55 18.84 6.65 18.72 6.34 18.05L5 15.1C3.18 13.82 2 11.79 2 9.5C2 5.63 5.36 2.5 9.5 2.5C12.52 2.5 15.13 4.17001 16.3 6.57001C16.75 7.46001 17 8.45 17 9.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M22.0001 13.36C22.0001 15.65 20.8201 17.6801 19.0001 18.9601L17.6601 21.91C17.3501 22.58 16.4501 22.7101 15.9801 22.1401L14.5001 20.36C12.0801 20.36 9.92007 19.2901 8.57007 17.6201L9.50006 16.5001C13.6401 16.5001 17.0001 13.3701 17.0001 9.50006C17.0001 8.45006 16.7501 7.46007 16.3001 6.57007C19.5701 7.32007 22.0001 10.08 22.0001 13.36Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.4"
        d="M7 9.5H12"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MessagesIcon;
