import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTabIndex: 0,
  uploadMediaOpen: false,
  sortOption: "Name",
  sortOrder: "Ascending",
};

export const vaultSlice = createSlice({
  name: "vault",
  initialState,
  reducers: {
    setActiveTabIndex: (state, { payload }) => {
      state.activeTabIndex = payload;
    },
    setUploadMediaOpen: (state, { payload }) => {
      state.uploadMediaOpen = payload;
    },
    setSortOption: (state, { payload }) => {
      state.sortOption = payload;
    },
    setSortOrder: (state, { payload }) => {
      state.sortOrder = payload;
    },
  },
});

export const {
  setActiveTabIndex,
  setUploadMediaOpen,
  setSortOption,
  setSortOrder,
} = vaultSlice.actions;

export default vaultSlice.reducer;
