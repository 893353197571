import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BlueLine from "./../../../../../assets/images/blue-line.svg";
import Button from "@mui/material/Button";
import MessagesBg from "./../../../../../assets/images/messagesbg.svg";
import StreamBg from "./../../../../../assets/images/streamsbg.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Datepicker, Timepicker } from "../../../../atoms";
import { closeCustomizableModal } from "../../../../../store/slices/common/customizableModal";
import { CloseImageIconButton } from "../../../../../assets/SVG/CloseImageIconButton";
import dayjs from "dayjs";
import { openToast } from "../../../../../store/slices/common/toast/index";
import { addPost } from "../../../../../store/middlewares/web/post/posts";
import { updatePost } from "../../../../../store/middlewares/web/post/posts";

const getDaysDifference = (date1, date2) => {
  const diffTime = Math.abs(new Date(date1) - new Date(date2));
  return Math.round(diffTime / (1000 * 60 * 60 * 24));
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AddQueueDialog = () => {
  const dispatch = useDispatch();
  const { modalId, modalData } = useSelector(
    (state) => state.customizableModal
  );
  const [tabIndex, setTabIndex] = useState(0);
  const [age, setAge] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedTime, setSelectedTime] = useState(dayjs());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    dispatch(closeCustomizableModal());
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handlePost = () => {
    const scheduledDateTime = selectedDate
      .hour(selectedTime.hour())
      .minute(selectedTime.minute())
      .second(selectedTime.second());

    if (scheduledDateTime.isBefore(dayjs())) {
      dispatch(
        openToast({
          type: "error",
          message:
            "Cannot schedule posts in the past. Please select a future date and time.",
        })
      );
      return;
    }

    const finalPostData = {
      ...modalData.postData,
      scheduleAt: scheduledDateTime.toISOString(),
    };

    if (modalData.postData.expiry) {
      const currentDate = new Date().toISOString();
      const daysToAdd = getDaysDifference(modalData.expiry, currentDate);
      const newExpiryDate = scheduledDateTime.add(daysToAdd, "days");
      finalPostData.expiry = newExpiryDate.toISOString();
    }

    setIsSubmitting(true);
    if (modalData.id) {
      dispatch(updatePost({ id: modalData.id, data: finalPostData }));
    } else {
      dispatch(addPost(finalPostData));
    }

    handleClose();
  };

  return (
    <Dialog
      open={modalId === "addQueue"}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Add to Queue</Typography>
        <CloseImageIconButton handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="queue options"
          >
            <Tab label="Scheduled" id="tab-0" aria-controls="tabpanel-0" />
            <Tab label="Save For Later" id="tab-1" aria-controls="tabpanel-1" />
          </Tabs>

          <TabPanel value={tabIndex} index={0}>
            <Box
              className="light-gray-bg rounded-2xl p-3 flex items-center"
              sx={{ mb: 2 }}
            >
              <img
                src={BlueLine}
                alt="BlueLine"
                style={{ marginRight: "12px" }}
              />
              <Typography variant="body2">
                Please choose a date and time for your action to be executed.
              </Typography>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ mt: 2, justifyContent: "space-between" }}
            >
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <Box sx={{ width: "95%" }}>
                  <Datepicker
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ width: "95%" }}>
                  <Timepicker
                    value={selectedTime}
                    onChange={handleTimeChange}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mb: 3 }}
            >
              <Button variant="contained" onClick={handlePost}>
                Post
              </Button>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ width: "95%" }}
                  variant="outlined"
                  className="white-shadow-btn mb-2"
                  endIcon={
                    <img
                      src={MessagesBg}
                      alt="MessagesBg"
                      height={30}
                      width={30}
                    />
                  }
                >
                  Mass Messages
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  sx={{ width: "95%" }}
                  variant="outlined"
                  className="white-shadow-btn"
                  endIcon={
                    <img src={StreamBg} alt="StreamBg" height={30} width={30} />
                  }
                >
                  Stream
                </Button>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <Box className="light-gray-bg rounded-2xl p-3 flex items-start">
              <img
                src={BlueLine}
                alt="BlueLine"
                style={{ marginRight: "12px" }}
              />
              <Typography variant="body2">
                Once the post is created, it will go to the end of the Saved for
                Later queue, and be published automatically, if this feature is
                turned on.
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <Select value={age} onChange={handleChange}>
                <MenuItem value={0}>6h since my last post</MenuItem>
                <MenuItem value={1}>6h since my last post</MenuItem>
                <MenuItem value={2}>12h since my last post</MenuItem>
                <MenuItem value={3}>24h since my last post</MenuItem>
                <MenuItem value={4}>48h since my last post</MenuItem>
              </Select>
            </FormControl>

            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 1 }}
            >
              <Button variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
                Cancel
              </Button>
              <Button variant="contained" disabled={isSubmitting}>
                {isSubmitting ? "Posting..." : "Post"}
              </Button>
            </Box>
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddQueueDialog;
