import React from "react";
import { Box, IconButton } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const StatItem = ({
  label,
  value,
  bgColor = "bg-gray-50",
  textColor = "text-black",
  isChecked,
  onToggle,
  lineColor,
}) => (
  <Box className="relative">
    <Box
      className="absolute left-0 top-1/2 -translate-y-1/2 w-1 rounded-full ml-2"
      sx={{
        backgroundColor: lineColor,
        height: "50%",
      }}
    />
    <Box
      className={`flex justify-between items-center py-3 px-4 rounded-lg ${bgColor} ${textColor} bg-opacity-80`}
    >
      <span className={`${textColor} ml-3`}>{label}</span>
      <div className="flex items-center gap-2">
        <span>{value}</span>
        {isChecked !== undefined && (
          <IconButton
            onClick={onToggle}
            size="small"
            sx={{
              padding: "0px",
              color: "inherit",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            {isChecked ? (
              <CheckBoxIcon fontSize="small" />
            ) : (
              <CheckBoxOutlineBlankIcon fontSize="small" />
            )}
          </IconButton>
        )}
      </div>
    </Box>
  </Box>
);
export default StatItem;
