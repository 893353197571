import React, { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const LoadMoreComments = ({ comments, onLoadMore, loading }) => {
  const loaderRef = useRef(null);
  const { isInitialCommentsLoaded } = useSelector((state) => state.posts);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loading) {
          if (isInitialCommentsLoaded && comments.length > 0) {
            onLoadMore();
          }
        }
      },
      { threshold: 0.1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [onLoadMore, loading, isInitialCommentsLoaded, comments.length]);

  return (
    <div ref={loaderRef} className="flex justify-center py-4">
      {loading && <CircularProgress />}
    </div>
  );
};

export default LoadMoreComments;
