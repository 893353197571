

import { forgot } from "../../../services/auth/forgotPassword/forgot.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (payload, { rejectWithValue,  fulfillWithValue }) => {
    try {
        const response = await forgot(payload);
        if (response.status === 1) {
            // if any async operation need to be done on response data, do it here,
            // then full fill data
            return fulfillWithValue({ ...response, email: payload.email });
        }
        return rejectWithValue({ message: response?.message });
    } catch (error) {
        return rejectWithValue(error);
    }
})
