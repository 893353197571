import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCustomizableModalState } from "../../../../../store/slices/common/customizableModal";
import ImageViewerModal from "../ImageViewerModal";
import AddQueueDialog from "../../creatorSide/ModalAddQueue";

const CustomizableModalContainer = React.memo(() => {
  const modalState = useSelector(selectCustomizableModalState);
  
  const modalContent = useMemo(() => {
    const { modalId, modalData } = modalState;
    if (!modalId) return null;
    
    switch (modalId) {
      case "imageViewer":
        return <ImageViewerModal imageUrl={modalData?.imageUrl} />;
      case "addQueue":
        return <AddQueueDialog />;
      default:
        return null;
    }
  }, [modalState]);

  return modalContent;
});

CustomizableModalContainer.displayName = 'CustomizableModalContainer';

export default CustomizableModalContainer;
