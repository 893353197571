import {
  AddCircleOutline,
  DragIndicator,
  KeyboardArrowDown,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { FieldArray, FormikProvider } from "formik";
import { CloseImageIconButton } from "../../../../../assets/SVG/CloseImageIconButton";
const PollSection = ({ setShowPoll, formik, title, isScheduledPost }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseForm = () => {
    formik.resetForm();
    setShowPoll(false);
  };

  const limitArray = [
    {
      label: "No Limit",
      value: null,
    },
    {
      label: "1 day",
      value: 1,
    },
    {
      label: "7 days",
      value: 7,
    },
    {
      label: "30 days",
      value: 30,
    },
  ];

  const [limit, setLimit] = useState(limitArray[0]);

  return (
    <div className="poll-data mt-6">
      <div className="flex justify-between items-center gap-3 border-b pb-3">
        <h6 className="font-20 text-darkblue font-semibold">{title}</h6>

        <div className="flex gap-3 items-center">
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
          >
            {limit?.label}
          </Button>
          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {limitArray?.map((ele) => {
              return (
                <MenuItem
                  onClick={() => {
                    setLimit(ele);
                    var result = new Date();
                    result.setDate(result.getDate() + ele.value);
                    if (ele.value) {
                      formik.setFieldValue(
                        "expiry",
                        new Date(result).toISOString()
                      );
                    } else {
                      formik.setFieldValue("expiry", "");
                    }
                    handleClose();
                  }}
                  disableRipple
                  key={ele.label}
                >
                  {ele.label}
                </MenuItem>
              );
            })}
          </Menu>
          {!isScheduledPost && (
            <CloseImageIconButton handleClose={handleCloseForm} />
          )}
        </div>
      </div>

      <div className="mt-4 add-payment-option">
        <FormikProvider value={formik}>
          <FieldArray
            name="options"
            render={({ push, remove }) => {
              return (
                <div>
                  {formik.values.options.map((option, index) => {
                    return (
                      <div className="relative mt-3" key={index}>
                        <FormControl className="w-full" variant="outlined">
                          <OutlinedInput
                            placeholder="Enter option"
                            id="outlined-adornment-password"
                            name={`options[${index}].text`}
                            value={option.text}
                            onChange={formik.handleChange}
                            type="text"
                            endAdornment={
                              <InputAdornment position="end" className="handle">
                                <>
                                  {formik.values.options.length > 1 && (
                                    <IconButton edge="end">
                                      <DragIndicator className="rotate-90" />
                                    </IconButton>
                                  )}
                                </>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        {formik.values.options.length > 1 && (
                          <IconButton
                            color="error"
                            className="pt-0 pb-0 remove-outline-circle"
                            onClick={() => remove(index)}
                          >
                            <RemoveCircleOutline color="error" />
                          </IconButton>
                        )}
                      </div>
                    );
                  })}
                  <Button
                    variant="outlined"
                    className="w-full text-primary mt-3"
                    onClick={() =>
                      push({
                        text: "",
                        sequence: formik.values.options.length + 1,
                      })
                    }
                  >
                    <AddCircleOutline className="mr-2" height="22" width="22" />
                    Add Another Option
                  </Button>
                </div>
              );
            }}
          />
        </FormikProvider>
      </div>
    </div>
  );
};

export default PollSection;
