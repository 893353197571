import React from "react";

export const HeartIcon = ({
  color = undefined,
  size = 28,
  type = "outline", //filled, outline
}) => {
  if (type === "outline") {
    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7259 24.2789C14.3293 24.4189 13.6759 24.4189 13.2793 24.2789C9.89594 23.1239 2.33594 18.3055 2.33594 10.1389C2.33594 6.53385 5.24094 3.61719 8.8226 3.61719C10.9459 3.61719 12.8243 4.64385 14.0026 6.23052C15.1809 4.64385 17.0709 3.61719 19.1826 3.61719C22.7643 3.61719 25.6693 6.53385 25.6693 10.1389C25.6693 18.3055 18.1093 23.1239 14.7259 24.2789Z"
          stroke={color ?? "#292D32"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0026 24.5001L12.3109 22.9835C10.347 21.214 8.72344 19.6876 7.4401 18.4043C6.15677 17.121 5.13594 15.9689 4.3776 14.948C3.61927 13.9272 3.08941 12.989 2.78802 12.1335C2.48663 11.2779 2.33594 10.4029 2.33594 9.50846C2.33594 7.68069 2.94844 6.1543 4.17344 4.9293C5.39844 3.7043 6.92483 3.0918 8.7526 3.0918C9.76371 3.0918 10.7262 3.30569 11.6401 3.73346C12.554 4.16124 13.3415 4.76402 14.0026 5.5418C14.6637 4.76402 15.4512 4.16124 16.3651 3.73346C17.279 3.30569 18.2415 3.0918 19.2526 3.0918C21.0804 3.0918 22.6068 3.7043 23.8318 4.9293C25.0568 6.1543 25.6693 7.68069 25.6693 9.50846C25.6693 10.4029 25.5186 11.2779 25.2172 12.1335C24.9158 12.989 24.3859 13.9272 23.6276 14.948C22.8693 15.9689 21.8484 17.121 20.5651 18.4043C19.2818 19.6876 17.6582 21.214 15.6943 22.9835L14.0026 24.5001Z"
          fill={color ?? "#F64027"}
        />
      </svg>
    );
  }
};
