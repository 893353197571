import React from "react";
import AddCardsIcon from "../assets/SVG/SideBar/AddCardsIcon";
import BookMarksIcon from "../assets/SVG/SideBar/BookMarksIcon";
import ExplorePageIcon from "../assets/SVG/SideBar/ExplorePageIcon";
import HomeIcon from "../assets/SVG/SideBar/HomeIcon";
import LiveStreamIcon from "../assets/SVG/SideBar/LiveStreamIcon";
import MessagesIcon from "../assets/SVG/SideBar/MessagesIcon";
import MoreIcon from "../assets/SVG/SideBar/MoreIcon";
import MyOrderIcon from "../assets/SVG/SideBar/MyOrderIcon";
import MyProfileIcon from "../assets/SVG/SideBar/MyProfileIcon";
import NotificationIcon from "../assets/SVG/SideBar/NotificationIcon";
import SubscriptionsIcon from "../assets/SVG/SideBar/SubscriptionsIcon";
import UserListsIcon from "../assets/SVG/SideBar/UserListsIcon";
import WishRequestsIcon from "../assets/SVG/SideBar/WishRequestsIcon";
import { returnLanguage } from "./languages";

const sideBarItems = (lang = "en") => {
  const langObj = returnLanguage(lang);
  return [
    {
      icon: <HomeIcon />,
      slug: "home",
      hasPage: true,
      label: langObj?.Home,
      count: 0,
    },
    {
      icon: <ExplorePageIcon />,
      slug: "explore",
      hasPage: true,
      label: langObj?.ExplorePage,
      count: 0,
    },
    {
      icon: <NotificationIcon />,
      slug: "notifications",
      hasPage: true,
      label: langObj?.Notification,
      count: 3,
    },
    {
      icon: <WishRequestsIcon />,
      slug: "wish-requests",
      hasPage: true,
      label: langObj?.WishRequest,
      count: 3,
    },
    {
      icon: <LiveStreamIcon />,
      slug: "live-stream",
      hasPage: true,
      label: langObj?.LiveStream,
      count: 0,
    },
    {
      icon: <MyOrderIcon />,
      slug: "my-order",
      hasPage: true,
      label: langObj?.MyOrder,
      count: 2,
    },
    {
      icon: <MessagesIcon />,
      slug: "messages",
      hasPage: true,
      label: langObj?.Messages,
      count: 0,
    },
    {
      icon: <UserListsIcon />,
      slug: "user-lists",
      hasPage: true,
      label: langObj?.UserLists,
      count: 0,
    },
    {
      icon: <BookMarksIcon />,
      slug: "bookmarks",
      hasPage: true,
      label: langObj?.Bookmarks,
      count: 0,
    },
    {
      icon: <SubscriptionsIcon />,
      slug: "subscriptions",
      hasPage: true,
      label: langObj?.Subscriptions,
      count: 0,
    },
    {
      icon: <AddCardsIcon />,
      slug: "payments",
      hasPage: true,
      label: langObj?.AddCard,
      count: 0,
    },
    {
      icon: <MyProfileIcon />,
      slug: "my-profile",
      hasPage: true,
      label: langObj?.MyProfile,
      count: 0,
    },
    {
      icon: <MoreIcon />,
      slug: "more",
      hasPage: true,
      label: langObj?.More,
      count: 0,
    },
  ];
};

export default sideBarItems;
