import React, { memo } from "react";
import { Modal, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { closeCustomizableModal } from "../../../../../store/slices/common/customizableModal";
import "./ImageViewerModal.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90vw",
  maxHeight: "90vh",
  bgcolor: "transparent",
  boxShadow: 24,
  p: 0,
};

const ImageViewerModal = memo(({ imageUrl }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeCustomizableModal());
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="image-viewer-modal"
      aria-describedby="full-screen-image-view"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <Close />
        </IconButton>
        <img
          src={imageUrl}
          alt="Full screen view"
          className="imageViewerModalImage"
        />
      </Box>
    </Modal>
  );
});

ImageViewerModal.displayName = "ImageViewerModal";

export default ImageViewerModal;
