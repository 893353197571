import React from "react";
import CloseSquare from "./../../../../../assets/images/close-square.svg";
import UpArrow from "./../../../../../assets/images/UpArrow.svg";
import OfferTag from "./../../../../../assets/images/offer-tag.png";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { languages } from "../../../../../utils/languages";

const SubscriptionPromotion = () => {
  return (
    <div className="bg-white rounded-2xl p-4">
      <div className="flex justify-between">
        <h3 className="uppercase font-semibold font-18 text-darkblue">
          {languages["en"].Subscription}
        </h3>
        <Link href="#">
          <img
            src={CloseSquare}
            alt="CloseSquare"
            className="img-dark-filter"
          />
        </Link>
      </div>

      <div className="light-blue-bg mt-4">
        <div className="flex justify-between w-full">
          <div>
            <h5 className="font-16 font-medium text-darkblue">
              {languages["en"].SubscriptionPromotionHeading}
            </h5>
            <p className="font-14 mt-1">
              {languages["en"].SubscriptionPromotionPrice}
            </p>
          </div>
        </div>
        <IconButton color="primary">
          <img src={UpArrow} alt="arrow" className="img-dark-filter" />
        </IconButton>
      </div>

      <div className="light-blue-bg mt-4">
        <div className="flex justify-between  w-full">
          <div className="flex items-start">
            <img src={OfferTag} alt="OfferTag" className="mr-3" />
            <div>
              <h5 className="font-16 font-medium text-darkblue">
                {languages["en"].SubscriptionPromotionOffer}
              </h5>
              <p className="font-14 mt-1">
                {languages["en"].SubscriptionPromotionCondition}
              </p>
            </div>
          </div>
        </div>
        <IconButton color="primary">
          <img src={UpArrow} alt="arrow" className="img-dark-filter" />
        </IconButton>
      </div>
    </div>
  );
};

export default SubscriptionPromotion;
