import React, { useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import DollarIcon from "../../../../../assets/images/dollar-square-blue.svg";
import { openModal } from "../../../../../store/slices/common/modal";
import ModalSendTip from "../../userProfile/ModalSendTip";

const SendTipButton = ({ creator }) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openModal({
          title: "SEND TIP",
          content: <ModalSendTip creator={creator} />,
          hasCloseBtn: true,
        })
      );
    },
    [dispatch, creator]
  );

  return (
    <Box className="flex items-center">
      <IconButton
        className="mr-2"
        aria-label="send tip"
        sx={{ padding: "0px !important" }}
        onClick={handleClick}
      >
        <img src={DollarIcon} alt="DollarIcon" />
      </IconButton>
      <Box
        className="font-medium font-16 cursor-pointer text-current"
        onClick={handleClick}
      >
        Send Tip
      </Box>
    </Box>
  );
};

export default React.memo(SendTipButton);
