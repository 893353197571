import { Close } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openCustomizableModal } from "../../../store/slices/common/customizableModal";

const DisplayImagesInPost = ({
  filesSrc,
  removeAble = false,
  removeImage,
  showDisplayMore = true,
}) => {
  const [cutImages, setCutImages] = useState(
    showDisplayMore ? 3 : filesSrc?.length
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    setCutImages(cutImages + 2);
  };

  const handleImageClick = (e, src) => {
    e.stopPropagation();
    dispatch(
      openCustomizableModal({
        modalId: "imageViewer",
        modalData: { imageUrl: src },
      })
    );
  };

  useEffect(() => {
    if (!showDisplayMore) {
      setCutImages(filesSrc?.length);
    }
  }, [filesSrc, showDisplayMore]);

  return (
    <Grid container>
      {filesSrc?.slice(0, cutImages).map((src, index) => (
        <Grid
          item
          xs={index === 0 ? 12 : 6}
          className={`normal-padding relative ${
            index % 2 !== 0 ? "pad-right" : ""
          } ${index === 0 ? "max-h-[400px]" : "max-h-[200px]"}`}
          key={`image-${index}`}
        >
          <div className="relative h-full">
            <img
              key={index}
              src={src}
              alt={`post-${index}`}
              className={`w-full object-cover h-full rounded-2xl cursor-pointer`}
              onClick={(e) => handleImageClick(e, src)}
            />
            {index === cutImages - 1 &&
              filesSrc.length - cutImages > 0 &&
              showDisplayMore && (
                <div
                  className="more-image cursor-pointer"
                  onClick={handleClick}
                >
                  <p className="mb-0 ">{filesSrc.length - cutImages} more</p>
                </div>
              )}
            {removeAble && (
              <IconButton
                disableRipple
                className={`remove-icon border bg-black ${
                  index % 2 !== 0 ? "extra-right" : ""
                } `}
                size="small"
                sx={{ color: "#fff" }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeImage(index);
                }}
              >
                <Close />
              </IconButton>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default DisplayImagesInPost;
