import axiosClient from "../../../../utils/api";

export const forgot = async (payload) => {
    try {
        const response = await axiosClient().request({
            url: "/auth/forgotPassword",
            method: "post",
            data: payload
        })
        return response.data
    } catch (error) {
        console.log("Error at Forgot Password Service", error);
        throw error?.response?.data ? error?.response?.data : error?.response
    }
};

