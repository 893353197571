import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";
const CreatePostImages = ({ filesSrc, removeAble = false, removeImage }) => {
  return (
    <>
      {filesSrc?.length > 0 && (
        <Box
          sx={{
            overflowX: "scroll",
            overflowY: "hidden",
            height: "220px",
            minWidth: "100%",
            whiteSpace: "nowrap",
          }}
        >
          {filesSrc?.map((src, index) => (
            <Box
              item
              xs={3}
              className={`normal-padding relative h-[200px] w-[200px] ${
                index !== filesSrc?.length - 1 ? "mr-4" : ""
              }`}
              key={`image-${index}`}
              sx={{
                height: "200px",
                width: "200px",
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              <img
                key={index}
                src={src?.fileURL ? src.fileURL : src}
                alt={`post-${index}`}
                className={`w-full object-cover h-full rounded-2xl`}
              />
              {removeAble && (
                <IconButton
                  disableRipple
                  className={"remove-icon border bg-black"}
                  size="small"
                  sx={{ color: "#fff" }}
                  onClick={() => removeImage(index)}
                >
                  <Close />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default CreatePostImages;
