import React from "react";
import {
  TbArticle,
  TbChecklist,
  TbHome,
  TbLanguage,
  TbMessageReport,
  TbSettings,
  TbUsersGroup,
  TbUserStar,
  TbWorldPin,
  TbSubmarine,
} from "react-icons/tb";

const menuData = [
  {
    id: "dashboard",
    label: "Dashboard",
    link: "/admin/dashboard",
    icon: <TbHome />,
  },
  {
    id: "users",
    label: "Users",
    link: "/#",
    icon: <TbUsersGroup />,
    subItems: [
      {
        id: "activeusers",
        label: "Active",
        link: "/admin/active-users",
        parentId: "users",
      },
      {
        id: "deletedusers",
        label: "Deleted",
        link: "/admin/delete-users",
        parentId: "users",
      },
    ],
  },
  {
    id: "creators",
    label: "Creators",
    link: "/#",
    icon: <TbUsersGroup />,
    subItems: [
      {
        id: "pendingcreators",
        label: "Pending",
        link: "/admin/pending-creators",
        parentId: "creators",
      },
      {
        id: "activecreators",
        label: "Active ",
        link: "/admin/active-creators",
        parentId: "creators",
      },
      {
        id: "deletedcreators",
        label: "Deleted",
        link: "/admin/delete-creators",
        parentId: "creators",
      },
    ],
  },
  {
    id: "posts",
    label: "Posts",
    link: "/#",
    icon: <TbArticle />,
    subItems: [
      {
        id: "active",
        label: "Active Posts",
        link: "/admin/active-posts",
        parentId: "posts",
      },
      {
        id: "deleted",
        label: "Deleted Posts",
        link: "/admin/delete-posts",
        parentId: "posts",
      },
    ],
  },
  {
    id: "blocked-users",
    label: "Blocked Users",
    link: "blocked-users",
    icon: <TbArticle />,
  },
  {
    id: "subscriptions",
    label: "Subscriptions",
    link: "subscriptions",
    icon: <TbSubmarine />,
  },
  {
    id: "restricted-users",
    label: "Restricted Users",
    link: "restricted-users",
    icon: <TbUserStar />,
  },
  {
    id: "reported",
    label: "Reported Users And Posts",
    link: "/#",
    icon: <TbMessageReport />,
    subItems: [
      {
        id: "report-reasons",
        label: "Report Reasons",
        link: "/admin/report-reasons",
        parentId: "reported",
        subItems: [
          {
            id: "users",
            label: "Users",
            link: "/admin/report-reasons/user",
            parentId: "reported",
          },
          {
            id: "posts",
            label: "Posts",
            link: "/admin/report-reasons/post",
            parentId: "reported",
          },
        ],
      },
      {
        id: "users",
        label: "Reported Users",
        link: "/admin/reported/users",
        parentId: "reported",
      },
      {
        id: "posts",
        label: "Reported Posts",
        link: "/admin/reported/posts",
        parentId: "reported",
      },
    ],
  },
  {
    id: "cms",
    label: "CMS",
    link: "/#",
    icon: <TbChecklist />,
    subItems: [
      {
        id: "terms",
        label: "Terms And Conditions",
        link: "/admin/cms/terms",
        parentId: "cms",
      },
      {
        id: "privacy",
        label: "Privacy Policy",
        link: "/admin/cms/privacy",
        parentId: "cms",
      },
      {
        id: "cookie-notice",
        label: "Cookie Notice",
        link: "/admin/cms/cookie-notice",
        parentId: "cms",
      },
      {
        id: "identity-verification-agreement-form",
        label: "Agreement Form",
        link: "/admin/cms/identity-verification-agreement-form",
        parentId: "cms",
      },
    ],
  },
  {
    id: "counrtry-code",
    label: "Country Code",
    icon: <TbWorldPin />,
    link: "/admin/country-code",
    parentId: 2,
  },
  {
    id: "states",
    label: "States",
    icon: <TbWorldPin />,
    link: "/admin/states",
    parentId: 2,
  },
  {
    id: "language",
    label: "Language",
    icon: <TbLanguage />,
    link: "/admin/languages",
  },
  {
    id: "document-types",
    label: "Document Types",
    icon: <TbLanguage />,
    link: "/admin/document-types",
  },
  {
    id: "setting",
    label: "Settings",
    icon: <TbSettings />,
    // link: "/admin/settings",
    subItems: [
      {
        id: "postSettings",
        label: "Post Settings",
        link: "/admin/settings/post",
        parentId: "settings",
      },
      {
        id: "generalSettings",
        label: "General Settings",
        link: "/admin/settings/general",
        parentId: "settings",
      },
    ],
  },
];

export { menuData };
