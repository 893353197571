import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "../hooks/useDocumentTitle";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { useTheme } from "../newComponents/atoms";
import VerifyIdentity from "../pages/auth/verifyIdentity/VerifyIdentity";

const CookieNotice = lazy(() => import("../pages/auth/cms/CookieNotice"));
const Privacy = lazy(() => import("../pages/auth/cms/Privacy"));
const Terms = lazy(() => import("../pages/auth/cms/Terms"));
const ForgotPassword = lazy(() =>
  import("../pages/auth/forgotPassword/ForgotPassword")
);
const Otp = lazy(() => import("../pages/auth/forgotPassword/Otp"));
const Register = lazy(() => import("../pages/auth/register/Register"));
const ResetPassword = lazy(() => import("../pages/auth/reset/ResetPassword"));
const TwoFA = lazy(() => import("../pages/auth/session/TwoFA"));
const Login = lazy(() => import("../pages/auth/session/Login"));

const basePath = "/";

const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};

const webRoutes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: generatePath("verify-totp"),
    element: <TwoFA />,
  },
  {
    path: generatePath("verify-identity"),
    element: <VerifyIdentity />,
  },
  {
    path: generatePath("register"),
    element: <Register />,
  },
  {
    path: generatePath("forgotpassword"),
    element: <ForgotPassword />,
  },
  {
    path: generatePath("otp"),
    element: <Otp />,
  },
  {
    path: generatePath("terms"),
    element: <Terms />,
  },
  {
    path: generatePath("privacy"),
    element: <Privacy />,
  },
  {
    path: generatePath("resetpassword"),
    element: <ResetPassword />,
  },
  {
    path: generatePath("cookie-notice"),
    element: <CookieNotice />,
  },
];

const WebNavigation = () => {
  useDocumentTitle("YouOnly");
  const user = JSON.parse(localStorage.getItem("user-details"));

  const { darkMode } = useTheme();
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            !user ? (
              <Login />
            ) : (
              <>
                {user?.userType === "creator" && (
                  <Navigate to="/creator/home" />
                )}
                {user?.userType === "user" && <Navigate to="/user/home" />}
              </>
            )
          }
        />
        {webRoutes?.map((route, index) => {
          return (
            <Route
              key={`${route.path}${index}`}
              path={route.path}
              element={route.element}
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </MuiThemeProvider>
  );
};

export default WebNavigation;
