import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../store/slices/common/modal";
import { CloseImageIconButton } from "../../../assets/SVG/CloseImageIconButton";

const Modal = () => {
  const { isOpen, actions, hasCloseBtn, content, title } = useSelector(
    (state) => state.modal
  );
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {title && (
        <DialogTitle
          sx={{
            textTransform: "uppercase",
            color: "#1E405C",
            fontSize: "20px !important",
            fontWeight: "600 !important",
            padding: "16px 20px !important",
          }}
        >
          {typeof title === "string" ? (
            <div
              className="flex justify-between items-center borderBottom pb-3"
              style={{ padding: "0 20px 20px !important" }}
            >
              {title}
              {hasCloseBtn && (
                <CloseImageIconButton handleClose={onClose} />
              )}
            </div>
          ) : (
            title
          )}
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: title ? "0 20px 20px !important" : "0" }}>
        {content}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
