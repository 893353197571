import React from "react";
import { Link, NavLink } from "react-router-dom";
import GrayDot from "./../../../../../assets/images/dot.svg";
import { languages } from "../../../../../utils/languages";

const PrivacySection = () => {
  return (
    <div className="bg-white rounded-2xl p-4 mt-3">
      <ul className="flex gap-3 flex-wrap justify-center">
        <li>
          <NavLink to="/privacy" className="text-darkblue">
            {languages["en"].Privacy}
          </NavLink>
        </li>
        <li>
          <Link to="/cookie-notice" className="text-darkblue flex items-center">
            <img src={GrayDot} alt="GrayDot" className="mr-2 gray-dot-width" />
            {languages["en"].CookieNotice}
          </Link>
        </li>
        <li>
          <NavLink to="/terms" className="text-darkblue flex items-center">
            <img src={GrayDot} alt="GrayDot" className="mr-2 gray-dot-width" />
            {languages["en"].TermsOfService}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default PrivacySection;
