import React from "react";
import BlankUser from "./../../../../../assets/images/blank.png";
import { CloseImageIconButton } from "../../../../../assets/SVG/CloseImageIconButton";

export const ProfileHeader = ({ username, date, image, onCancel }) => (
  <div className="flex items-center justify-between mb-2">
    <div className="flex items-center">
      <img
        src={image ?? BlankUser}
        alt="Profile"
        className="w-8 h-8 rounded-full mr-2"
      />
      <div>
        <h2 className="font-semibold text-sm text-darkblue">{username}</h2>
        <p className="text-xs text-gray-500">{date}</p>
      </div>
    </div>
    <CloseImageIconButton handleClose={onCancel} />
  </div>
);
