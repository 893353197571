import * as Yup from "yup";
import {
  spaceRegExp,
  specialCharactersRegExp,
  uppercaseRegExp,
} from "../regex";

export const oldPassword = Yup.string()
  .min(8, "Password should be at least 8 characters long")
  .matches(
    uppercaseRegExp,
    "Password must contain at least one uppercase letter"
  )
  .matches(
    specialCharactersRegExp,
    "Password must contain at least one special character"
  )
  .matches(spaceRegExp, "Password cannot contain spaces")
  .max(50, "Password should not contain more than 50 characters")
  .required("Password is Required");

export const newPassword = Yup.string()
  .min(8, "Password should be at least 8 characters long")
  .matches(
    uppercaseRegExp,
    "Password must contain at least one uppercase letter"
  )
  .matches(
    specialCharactersRegExp,
    "Password must contain at least one special character"
  )
  .matches(spaceRegExp, "Password cannot contain spaces")
  .max(50, "Password should not contain more than 50 characters")
  .required("New Password is Required");

export const confirmPassword = Yup.string()
  .oneOf(
    [Yup.ref("newPassword")],
    "New Password and Confirm Password do not match!"
  )
  .required("Confirm Password is Required");
