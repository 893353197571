import SearchIcon from "./../../../../../assets/images/search-normal.svg";
import React from "react";
import ListMenuBlackIcon from "./../../../../../assets/images/listmenublack.svg";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { closeModal } from "../../../../../store/slices/common/modal";
import { CloseImageIconButton } from "../../../../../assets/SVG/CloseImageIconButton";

const SaveToListTitle = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };
  return (
    <div className="flex justify-between items-center borderBottom pb-3">
      <h3>SAVE TO LIST</h3>
      <div className="flex flex-wrap gap-2">
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          className="pt-0 pb-0"
          onClick={handleClose}
        >
          <img
            src={SearchIcon}
            alt="SearchIcon"
            height={24}
            width={24}
            className="img-dark-filter"
          />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={handleClose}
          className="pt-0 pb-0"
        >
          <img
            src={ListMenuBlackIcon}
            alt="ListMenuBlackIcon"
            height={24}
            width={24}
            className="img-dark-filter"
          />
        </IconButton>
        <CloseImageIconButton handleClose={handleClose} />
      </div>
    </div>
  );
};

export default SaveToListTitle;
