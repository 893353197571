import LocalstorageService from "../../../../utils/localstorage-services";
import {
  login,
  verifytotp,
  logout,
  validateToken,
  verifyIdentity,
  resendIdentityVerificationEmail
} from "../../../services/auth/session/auth.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeModal } from "../../../slices/common/modal";

export const verifyTOTPAction = createAsyncThunk(
  "auth/verifytotp",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await verifytotp(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {   
      return rejectWithValue(error);
    }
  }
);

export const verifyIdentityAction = createAsyncThunk(
  "auth/verifyIdentity",
  async (token, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await verifyIdentity(token);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resendIdentityVerificationEmailAction = createAsyncThunk(
  "auth/resendIdentityVerificationEmail",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await resendIdentityVerificationEmail(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loginAction = createAsyncThunk(
  "auth/loginUser",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await login(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...res, email: payload.email });
      }
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const validateTokenWeb = createAsyncThunk(
  "auth/validateTokenWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await validateToken(payload);
      if (!res?.response?.isTokenExpired) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      LocalstorageService.removeFromLocalStorage("user-details")
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const validateTokenAdmin = createAsyncThunk(
  "auth/validateTokenAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await validateToken(payload);
      if (!res?.response?.isTokenExpired) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      LocalstorageService.removeFromLocalStorage("user-details-admin")
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logoutAction = createAsyncThunk(
  "auth/logout",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await logout(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeModal());
        LocalstorageService.removeFromLocalStorage("user-details");
        window.location = "/";
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
