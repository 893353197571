import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Edit from "./../../../assets/images/edit-2.svg";
import UserBlank from "./../../../assets/images/blank.png";
import Gallery from "./../../../assets/images/gallery.svg";
import AlignLeft from "./../../../assets/images/align-left.svg";
import MessageQuestion from "./../../../assets/images/message-question.svg";
import DirectRight from "./../../../assets/images/direct-right.svg";

const CreatePostSection = () => {
  const { me } = useSelector((state) => state.me);
  const navigate = useNavigate();
  return (
    <div
      className="mt-5 bg-white rounded-2xl shadowsm"
      onClick={() => navigate(`/${me?.userType}/post`)}
    >
      <div className={`flex flex-wrap items-center mb-2 p-3 pb-0`}>
        <Box className="min-h-[44px] h-[44px] min-w-[44px] w-[44px] mr-2">
          <img
            src={me?.profileUrl ? me?.profileUrl : UserBlank}
            alt={me?.name}
            className="w-full h-full rounded-[50%] object-cover"
          />
        </Box>
        <textarea
          placeholder="Compose a post...."
          className={`text-area bg-white h-[24px] focus:border-none focus:outline-none`}
        />
        <IconButton
          color="primary"
          sx={{
            paddingTop: "0",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <img
            src={Edit}
            alt="edit icon"
            width={18}
            className="img-dark-filter"
          />
        </IconButton>
      </div>

      <div className={`flex flex-wrap items-center justify-between mt-6 pl-3 `}>
        <div className="flex flex-wrap items-center gap-4">
          <img src={MessageQuestion} alt="Video" className="img-dark-filter" />
          <img src={AlignLeft} alt="Align Left" className="img-dark-filter" />
          <img src={Gallery} alt="Gallery" className="img-dark-filter" />
        </div>

        <div className="feed-box flex">
          <Button
            variant="contained"
            sx={{ borderRadius: "0px 0px 16px 0px !important" }}
            disabled={true}
          >
            <img
              src={DirectRight}
              alt="DirectRight"
              className="img-dark-filter"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreatePostSection;
