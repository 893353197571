import React, { useEffect } from "react";
import { HomeStories } from "../../../../atoms";
import StoryCreator from "../StoryModal/StoryCreator";
import { useDispatch, useSelector } from "react-redux";
import { getStoriesAction } from "../../../../../store/middlewares/web/post/posts";

const HomeStoriesSection = ({ isCreator = false }) => {
  const dispatch = useDispatch();
  const { stories } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(getStoriesAction());
  }, [dispatch]);

  return (
    <div className="flex overflow-x-auto  gap-4 profile-images hidden-scrollbar">
      {isCreator && <StoryCreator />}
      {stories?.map((story) => {
        return <HomeStories key={story?.id} story={story} />;
      })}
    </div>
  );
};

export default HomeStoriesSection;
