/**
 * @fileOverview This file contains the axiosClient utility function and related constants for making HTTP requests.
 * @module axiosClient
 */

import axios from "axios";
import { getDecryptedToken } from "./CryptoJS";

axios.defaults.withCredentials = false;
let apiURL = "";
let accountURL = "";
let adminURL = "";

/**
 * Configuration options for the you only environment.
 * @type {Object}
 */
const youOnly = {
  name: "youonly",
  account: "",
  api: `${process.env.REACT_APP_API_URL || "https://api-dev.youonly.io"}`,
  live: `${process.env.REACT_APP_LIVE_URL || "https://web-dev.youonly.io"}`,
  admin: `${
    process.env.REACT_APP_ADMIN_URL || "https://web-dev.youonly.io/admin"
  }`,
};

/**
 * Configuration options for the localhost environment.
 * @type {Object}
 */
const localhost = {
  account: "http://localhost:3000",
  admin: "http://localhost:3000/admin",
  api: "http://localhost:8080",
};

const path = window.location.href;
const live = !!path.match(youOnly.live);

if (live) {
  accountURL = youOnly.account;
  apiURL = youOnly.api;
  adminURL = youOnly.admin;
} else {
  accountURL = localhost.account;
  apiURL = localhost.api;
  adminURL = localhost.admin;
}

export const API_URL = apiURL;
export const ACCOUNT_URL = accountURL;

/**
 * The axios client function for making HTTP requests to api.
 * @function axiosClient
 */
export function axiosClient() {
  // gets the email and refresh cookies
  const adminToken = JSON.parse(
    localStorage.getItem("user-details-admin")
  )?.token;
  const userToken = JSON.parse(localStorage.getItem("user-details"))?.token;

  let token = "";

  const path = window.location.href;
  const admin = path.includes(adminURL);
  if (admin) {
    token = adminToken;
  } else {
    token = userToken;
  }

  const defaultOptions = {
    baseURL: apiURL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
    },
  };
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    config.headers = {
      Authorization: `Bearer ${getDecryptedToken(token)}`,
    };
    return config;
  });
  instance.defaults.headers["Authorization"] = getDecryptedToken(token);

  return instance;
}

export default axiosClient;

export const appendFilesToFormData = (formData, files) => {
  if (files) {
    files.forEach((file, index) => {
      let fileType, blob, extension;

      if (file instanceof File) {
        fileType = file.type.startsWith("audio/") ? "audio" : "unknown";
        blob = file;
        extension = file.name.split(".").pop();
      } else if (typeof file === "string" && file.startsWith("data:")) {
        const byteString = atob(file.split(",")[1]);
        const mimeString = file.split(",")[0].split(":")[1].split(";")[0];
        fileType = mimeString.startsWith("video/") ? "video" : "image";

        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          byteArray[i] = byteString.charCodeAt(i);
        }

        blob = new Blob([byteArray], { type: mimeString });
        extension = fileType === "video" ? "mp4" : "jpg";
      } else {
        console.error("Unsupported file type:", file);
        return;
      }

      formData.append("file", blob, `file-${index}.${extension}`);
    });
  }
  return formData;
};
