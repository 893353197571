import { useState, useEffect } from "react";
import dayjs from "dayjs";

export const useOptionPostExpiry = (expiryDate) => {
  const [isPostExpired, setIsPostExpired] = useState(false);
  const [timeLeftText, setTimeLeftText] = useState("");

  useEffect(() => {
    const updateExpiry = () => {
      if (!expiryDate) {
        return;
      }
      const now = dayjs();
      const expiry = dayjs(expiryDate);

      if (now.isAfter(expiry)) {
        setIsPostExpired(true);
        setTimeLeftText("Expired");
        return;
      }

      const daysLeft = expiry.diff(now, "days");
      const hoursLeft = expiry.diff(now, "hours") % 24;
      const minutesLeft = expiry.diff(now, "minutes") % 60;

      if (daysLeft > 0) {
        setTimeLeftText(`${daysLeft} days left`);
      } else if (hoursLeft > 0) {
        setTimeLeftText(`${hoursLeft} hours left`);
      } else if (minutesLeft > 0) {
        setTimeLeftText(`${minutesLeft} minutes left`);
      } else {
        setTimeLeftText("Less than a minute left");
      }
    };

    updateExpiry();
    const timer = setInterval(updateExpiry, 60000);

    return () => clearInterval(timer);
  }, [expiryDate]);

  return { isPostExpired, timeLeftText };
};
