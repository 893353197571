import { IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "./../../../assets/images/arrow-left.svg";

const PageHeader = ({
  title,
  rightToolbar = null,
  noPadding = false,
  iconNoPadding = false,
  className,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`bg-white rounded-2xl ${
        noPadding ? "p-0" : "p-3"
      } ${className}`}
    >
      <div className="flex flex-wrap items-center justify-between heading">
        <div className="flex items-center gap-3">
          <IconButton
            color="primary"
            disableRipple
            aria-label="navigate-back"
            className={iconNoPadding ? "pt-0 pb-0" : ""}
            onClick={() => {
              const previousLocation = document.referrer;
              if (
                !previousLocation ||
                new URL(previousLocation).origin !== window.location.origin
              ) {
                navigate("/");
              } else {
                navigate(-1);
              }
            }}
          >
            <img
              src={ArrowLeft}
              alt="ArrowLeft"
              height={26}
              width={26}
              className="img-dark-filter"
            />
          </IconButton>
          {title && (
            <h3 className="font-18 font-semibold text-darkblue uppercase">
              {title}
            </h3>
          )}
        </div>
        {rightToolbar ? rightToolbar : <></>}
      </div>
    </div>
  );
};

export default PageHeader;
