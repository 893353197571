import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import UserBlank from "./../../../assets/images/blank.png";
import { useDispatch, useSelector } from "react-redux";
import { addComentsToPost } from "../../../store/middlewares/web/post/posts";
import { Send } from "@mui/icons-material";
import { getColor } from "../../../utils/colors";
import "./AddCommentTextArea.css";

const AddCommentTextArea = ({ postId }) => {
  const [focus, setFocus] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const textNewRef = useRef(); // ref the textArea

  const { me } = useSelector((state) => state.me);
  const { updatePostsSuccess } = useSelector((state) => state.posts);
  const dispatch = useDispatch();

  // const [isEdit, SetIsEdit] = useState(null)

  const onChangeHandler = function (e) {
    //on change handler to increase height as the user types
    if (e.target.value) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
    const target = e.target;
    textNewRef.current.style.height = "16px";
    textNewRef.current.style.height = `${target.scrollHeight}px`;
  };

  const handleAddComment = () => {
    let reqObj = {
      postId,
      comment: textNewRef?.current?.value,
    };
    dispatch(addComentsToPost(reqObj)); // add comment
    textNewRef.current.value = "";
  };

  useEffect(() => {
    //reset after adding comment
    if (textNewRef.current && updatePostsSuccess) {
      textNewRef.current.blur();
      textNewRef.current.value = "";
      textNewRef.current.style.height = "24px";
      setFocus(false);
      setHasValue(false);
      // SetIsEdit(null)
    }
  }, [updatePostsSuccess, textNewRef, dispatch]);

  return (
    <>
      <div
        className={`mt-5 mb-5 bg-white rounded-2xl shadowsm p-3 flex write-comment gap-3 items-center main-text-area-comment`}
      >
        <Box className="min-h-[44px] h-[44px] min-w-[44px] w-[44px] mr-2 flex items-center justify-center">
          <img
            src={me?.profileUrl ? me?.profileUrl : UserBlank}
            alt={me?.name}
            className="w-full h-full rounded-[50%] object-cover pointer-events-none"
          />
        </Box>
        <textarea
          ref={textNewRef}
          onChange={onChangeHandler}
          placeholder="Write a comment..."
          className={`text-area bg-white ${
            focus ? "focused" : ""
          } focus:border-none focus:outline-none text-area-comment`}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            if (!textNewRef?.current?.value) {
              setFocus(false);
            }
          }}
        />
        <IconButton
          disabled={!hasValue}
          onClick={() => {
            handleAddComment();
          }}
          sx={{
            color: getColor("primary"),
            display: "flex",
            alignItems: "center",
          }}
        >
          <Send size={28} />
        </IconButton>
      </div>
    </>
  );
};

export default AddCommentTextArea;
