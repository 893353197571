import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import ShareBlackIcon from "./../../../../../assets/images/share-black.png";
import MoreSquare from "./../../../../../assets/images/more-square.svg";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../../store/slices/common/modal";
import ModalConfirmDeletePost from "../../home/ModalConfirmDeletePost";
import { useNavigate } from "react-router-dom";
import { openToast } from "../../../../../store/slices/common/toast";
import { ACCOUNT_URL } from "../../../../../utils/api";
import axiosClient from "../../../../../utils/api";
import { useSelector } from "react-redux";
import StatisticsModal from "../StatisticsModal";
import { removePost } from "../../../../../store/slices/common/posts";

const UserPostDropdown = ({
  post,
  displayCopyLink = true,
  displayPinToProfile = true,
  displaySharePostToStory = true,
  displayEdit = true,
  displayArchivePost = true,
  displayAddATag = true,
  displayDeletePost = true,
  displayViewDetailedStatistics = true,
  displayShareIcon = true,
}) => {
  const { me } = useSelector((state) => state.me);
  let { id } = post;
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const [isPinned, setIsPinned] = useState(!!me?.pinnedPostId);
  const [isArchived, setIsArchived] = useState(post.isArchived);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); //set Anchor for Menu
  };

  const handleClose = () => {
    setAnchorEl(null); //reset Anchor for Menu
  };

  const handleDeletPost = () => {
    dispatch(
      openModal({
        title: "DELETE POST",
        content: <ModalConfirmDeletePost postId={id} />,
      })
    );
  };

  const handleEdit = () => {
    navigate(`/creator/edit-post/${id}`);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${ACCOUNT_URL}/user/post/${id}`);
    dispatch(
      openToast({
        type: "success",
        message: "Link copied to clipboard",
      })
    );
    handleClose();
  };

  const handlePinToProfile = async () => {
    const requestObject = {
      url: `/post/pinPost/${id}`,
      method: "patch",
    };

    if (isPinned) {
      requestObject.method = "delete";
      requestObject.url = `/post/pinPost`;
    }

    const response = await axiosClient().request(requestObject);

    setIsPinned(!isPinned);
    if (response.status === 200) {
      dispatch(
        openToast({
          type: "success",
          message: `Post ${
            isPinned ? "unpinned" : "pinned"
          } from your profile page`,
        })
      );
    } else {
      dispatch(
        openToast({
          type: "error",
          message: `Failed to ${isPinned ? "unpin" : "pin"} post`,
        })
      );
    }
    handleClose();
  };

  const handleArchivePost = async () => {
    try {
      const response = await axiosClient().request({
        url: `/post/${isArchived ? "unarchive" : "archive"}/${id}`,
        method: "patch",
      });

      if (response.status === 200) {
        setIsArchived(!isArchived);
        dispatch(
          openToast({
            type: "success",
            message: `Post ${
              isArchived ? "unarchived" : "archived"
            } successfully`,
          })
        );

        dispatch(removePost({ postId: id }));
      }
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: `Failed to ${isArchived ? "unarchive" : "archive"} post`,
        })
      );
    }
    handleClose();
  };

  const handleViewDetailedStatistics = () => {
    dispatch(
      openModal({
        title: "STATISTICS",
        content: <StatisticsModal postId={id} />,
        hasCloseBtn: true,
      })
    );
  };

  return (
    <>
      {displayShareIcon && (
        <IconButton
          size="small"
          aria-controls={open ? "more-detail" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <img
            src={ShareBlackIcon}
            alt="ShareIcon"
            width={24}
            className="img-dark-filter"
          />
        </IconButton>
      )}
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? "more-detail" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <img
          src={MoreSquare}
          alt="MoreSquare"
          width={24}
          className="img-dark-filter"
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="more-detail"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {displayCopyLink && (
          <MenuItem onClick={handleCopyLink}>Copy Link to Post</MenuItem>
        )}
        {displayPinToProfile && (
          <MenuItem onClick={handlePinToProfile}>
            {isPinned ? "Unpin Post" : "Pin To your Profile Page"}
          </MenuItem>
        )}
        {displaySharePostToStory && (
          <MenuItem onClick={handleClose}>Share Post To Story</MenuItem>
        )}
        {displayEdit && <MenuItem onClick={handleEdit}>Edit Post </MenuItem>}
        {displayArchivePost && (
          <MenuItem onClick={handleArchivePost}>
            {isArchived ? "Unarchive Post" : "Archive Post"}
          </MenuItem>
        )}
        {displayAddATag && <MenuItem onClick={handleClose}>Add A Tag</MenuItem>}
        <Divider />
        {displayDeletePost && (
          <MenuItem onClick={handleDeletPost}>Delete Post</MenuItem>
        )}
        <Divider />
        {displayViewDetailedStatistics && (
          <MenuItem onClick={handleViewDetailedStatistics}>
            View Detailed Statistics
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default UserPostDropdown;
