const getMonthCount = (key) => {
  let count = 0;
  switch (key) {
    case "threeMonths":
      count = 3;
      break;
    case "sixMonths":
      count = 6;
      break;
    case "twelveMonths":
      count = 12;
      break;
    case "oneMonths":
      count = 1;
      break;
    case "oneMonth":
      count = 1;
      break;
    default:
      count = 0;
      break;
  }
  return count;
};

export default getMonthCount;
