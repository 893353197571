import LocalstorageService from "../../../../utils/localstorage-services";
import {
  changePassword,
  loginAdmin,
} from "../../../services/admin/session/auth.js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "../../../slices/common/toast/index.js";
import { encryptAndStoreToken } from "../../../../utils/CryptoJS.js";

export const LoginAdminAction = createAsyncThunk(
  "login/loginAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await loginAdmin(payload);
      if (res.status === 1) {
        const { response } = res;
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        const localStorage = { user: {...response, token: encryptAndStoreToken(response?.token)} };
        // const localStorage = { user: {...response, token: encryptToken(response?.token)} };
        LocalstorageService.loginUser(localStorage);
        window.location = "/admin/dashboard";
        return fulfillWithValue(res);
      }
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      return rejectWithValue({ message: error?.response?.data?.message });
    }
  }
);

export const ChangeAdminPasswordAction = createAsyncThunk(
  "admin/changePassword",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await changePassword(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);
