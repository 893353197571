import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  actions: null,
  hasCloseBtn: false,
  content: null,
  title: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.isOpen = true;
      state.actions = payload?.actions;
      state.hasCloseBtn = payload?.hasCloseBtn;
      state.content = payload?.content;
      state.title = payload?.title;
    },
    closeModal: (state) => {
      return initialState;
    },
  },
});

export const selectModal = (state) => state.modal;

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
