import React, { useState, useEffect } from "react";
import { Box, Divider, CircularProgress } from "@mui/material";
import StatItem from "./StatItem";
import StatisticsChart from "./StatisticsChart";
import { getPostStats } from "../../../../../store/services/web/post/posts.services";

//main used for vertical line
//do we handle diff?
export const STAT_COLORS = {
  purchases: {
    main: "#2563eb", // blue-600
    bg: "bg-blue-50",
    text: "text-blue-600",
  },
  views: {
    main: "#ef4444", // red-500
    bg: "bg-red-50",
    text: "text-red-500",
  },
  likes: {
    main: "#22c55e", // green-500
    bg: "bg-green-50",
    text: "text-green-500",
  },
  comments: {
    main: "#d97706", // amber-600
    bg: "bg-amber-50",
    text: "text-amber-600",
  },
  tips: {
    main: "#9333ea", // purple-600
    bg: "bg-purple-50",
    text: "text-purple-600",
  },
  duration: {
    main: "#6b7280", // gray-500
    bg: "bg-gray-50",
    text: "text-gray-600",
  },
  average: {
    main: "#6b7280", // gray-500
    bg: "bg-gray-50",
    text: "text-gray-600",
  },
};

const StatisticsModal = ({ postId }) => {
  const [checkedStats, setCheckedStats] = useState({
    purchases: true,
    views: true,
    likes: true,
    comments: true,
    tips: true,
  });

  const [statsData, setStatsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const response = await getPostStats(postId);
        setStatsData(response.response);
      } catch (err) {
        setError(err.message || "Failed to fetch statistics");
      } finally {
        setLoading(false);
      }
    };

    if (postId) {
      fetchStats();
    }
  }, [postId]);

  const handleToggle = (statName) => {
    setCheckedStats((prev) => ({
      ...prev,
      [statName]: !prev[statName],
    }));
  };

  if (loading) {
    return (
      <Box className="p-4 w-full max-w-md flex justify-center items-center min-h-[200px]">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="p-4 w-full max-w-md text-center text-red-500">
        {error}
      </Box>
    );
  }

  return (
    <Box className="p-4 w-full max-w-md">
      <StatisticsChart
        checkedStats={checkedStats}
        colors={STAT_COLORS}
        data={statsData}
      />

      {/* Stats List */}
      <Box className="space-y-3">
        <StatItem
          label="Purchases"
          value="0($0.00)"
          bgColor={STAT_COLORS.purchases.bg}
          textColor={STAT_COLORS.purchases.text}
          lineColor={STAT_COLORS.purchases.main}
          isChecked={checkedStats.purchases}
          onToggle={() => handleToggle("purchases")}
        />
        <StatItem
          label="Views"
          value={statsData?.totals?.views || 0}
          bgColor={STAT_COLORS.views.bg}
          textColor={STAT_COLORS.views.text}
          lineColor={STAT_COLORS.views.main}
          isChecked={checkedStats.views}
          onToggle={() => handleToggle("views")}
        />
        <StatItem
          label="Likes"
          value={statsData?.totals?.likes || 0}
          bgColor={STAT_COLORS.likes.bg}
          textColor={STAT_COLORS.likes.text}
          lineColor={STAT_COLORS.likes.main}
          isChecked={checkedStats.likes}
          onToggle={() => handleToggle("likes")}
        />
        <StatItem
          label="Comments"
          value={statsData?.totals?.comments || 0}
          bgColor={STAT_COLORS.comments.bg}
          textColor={STAT_COLORS.comments.text}
          lineColor={STAT_COLORS.comments.main}
          isChecked={checkedStats.comments}
          onToggle={() => handleToggle("comments")}
        />
        <StatItem
          label="Tips"
          value="0($0.00)"
          bgColor="bg-purple-50"
          textColor="text-purple-600"
          lineColor="#9333ea" // purple-600
          isChecked={checkedStats.tips}
          onToggle={() => handleToggle("tips")}
        />
      </Box>

      <Box className="mt-6 mb-4">
        <p className="text-sm text-gray-500 italic">
          Some statistics may be delayed or approximated
        </p>
      </Box>

      <Divider />

      {/* Duration Stats -------------------------------- */}
      <Box className="mt-4 space-y-3">
        <StatItem
          label="Duration"
          value="00:00:00"
          bgColor="bg-gray-50"
          lineColor="#6b7280" // gray-500
        />
        <StatItem
          label="Average"
          value="00:00:00"
          bgColor="bg-gray-50"
          lineColor="#6b7280" // gray-500
        />
      </Box>
    </Box>
  );
};

export default StatisticsModal;
