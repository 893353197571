import { AccessTime } from "@mui/icons-material";
import { Button, FormHelperText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { languages } from "../../../../utils/languages";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../../store/middlewares/auth/forgotPassword/forgot";

const OtpFields = ({
  handleSubmit,
  inputRefs,
  error = false,
  errorMsg = "",
  hasReset = false,
}) => {
  const dispatch = useDispatch();
  const { webforgotEmail } = useSelector((state) => state.webforgot);
  const initialTime = 120; // Set initial time to 120 seconds (2 minutes)
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [otpValues, setOtpValues] = useState(Array(6).fill(""));
  const [errors, setErrors] = useState(Array(6).fill(false));

  // Handle change in individual OTP fields
  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value) {
      const newErrors = [...errors];
      newErrors[index] = false;
      setErrors(newErrors);
    }

    e.target.value = value;

    if (value.length === 1 && index < inputRefs.current.length - 1) {
      // Focus on the next input field
      inputRefs.current[index + 1].focus();
    }
  };

  // Handle paste event for the entire OTP
  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("text").replace(/\D/g, ""); // Get only numeric values from pasted data
    if (pastedData.length === inputRefs.current.length) {
      const newOtpValues = [...otpValues];
      // Split pasted data and populate the fields
      for (let i = 0; i < inputRefs.current.length; i++) {
        inputRefs.current[i].value = pastedData[i] || ""; // Ensure no overfill
        newOtpValues[i] = pastedData[i] || "";
      }
      setOtpValues(newOtpValues);
      setErrors(Array(6).fill(false)); 
      // Automatically focus the last input after pasting
      inputRefs.current[inputRefs.current.length - 1].focus();
    }
    e.preventDefault(); // Prevent the default paste behavior
  };

  // Handle backspace navigation
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      // Focus on the previous field if backspace is pressed
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  // Format time in MM:SS format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  // Resend OTP handler
  const handleResendOTP = () => {
    const payload = {
      email: webforgotEmail.email,
    };

    dispatch(forgotPassword(payload));

    if (timeLeft === 0) {
      setTimeLeft(initialTime);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newErrors = otpValues.map((value) => value === "");
    setErrors(newErrors);

    const hasError = newErrors.some((error) => error);

    if (hasError) {
      return;
    }

    handleSubmit(e);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="flex gap-3 otp-text" onPaste={handlePaste}>
        {[...Array(6)].map((_, index) => (
          <TextField
            key={index}
            fullWidth
            inputRef={(el) => (inputRefs.current[index] = el)}
            placeholder="0"
            variant="outlined"
            error={errors[index]}
            name={`otp-${index}`}
            inputProps={{ maxLength: 1, type: "text", pattern: "[0-9]*" }}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            sx={{ textAlign: "center !important" }}
          />
        ))}
      </div>
      {error && <FormHelperText error={true}>{errorMsg}</FormHelperText>}
      {hasReset && (
        <div className="flex justify-between mb-4 mt-2">
          <div className="flex">
            <AccessTime className="mr-1" />
            <span>{`${formatTime(timeLeft)} Sec`}</span>
          </div>
          <Link
            className={`${
              timeLeft === 0
                ? "text-[#2799F6] cursor-pointer"
                : " otp-color cursor-default"
            }`}
            onClick={handleResendOTP}
          >
            {languages["en"].ResendOTP}
          </Link>
        </div>
      )}
      <Button
        variant="contained"
        className="w-full"
        sx={{ marginTop: "10px" }}
        type="submit"
      >
        {languages["en"].Submit}
      </Button>
    </form>
  );
};

export default OtpFields;
